import React, { useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import { useAuth } from '../services/AuthContext'
import { Link } from 'gatsby'

const Login = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [isBusy, setBusy] = useState(false)

  const handleSubmit = async (evt) => {
    evt.preventDefault()
    try {
      setBusy(true)
      await firebase.auth().signInWithEmailAndPassword(email, password);
      setBusy(false)
    } catch (err) {
      console.error(err.message)
      setError(err.message)
    }
  }

  const inputClass = "bg-gray-100 mb-2 px-3 py-2 rounded-lg w-full disabled:bg-opacity-50"
  return (
    <main class="h-screen grid place-items-center">
      <form class="w-96" onSubmit={handleSubmit}>
        {error && <div className="text-red-500">{error}</div>}
        <div class="inputs">
          <input
            type="email"
            name="email"
            placeholder="e-mail"
            required
            className={inputClass}
            value={email}
            onChange={e => setEmail(e.target.value)}
            disabled={isBusy}
          />
          <input
            type="password"
            name="email"
            placeholder="password"
            required
            className={inputClass}
            value={password}
            onChange={e => setPassword(e.target.value)}
            disabled={isBusy}
          />
        </div>
        <button
          class="px-3 py-2 bg-primary text-white block w-full rounded-lg bg-opacity-90 hover:bg-opacity-100 disabled:bg-opacity-50 cursor-pointer"
          disabled={isBusy}
        >
          {isBusy ? "•••" : "Connexion"}
        </button>
      </form>
    </main>
  )
}

const Logout = ({ onLogout }) => {
  const [isBusy, setBusy] = useState(false)
  const [error, setError] = useState("")

  const handleSubmit = async (evt) => {
    evt.preventDefault()
    try {
      setBusy(true)
      await firebase.auth().signOut()
      setBusy(false)
      onLogout && onLogout()
    } catch (err) {
      setError(err.message)
    }
  }

  return (
    <main class="h-screen grid place-items-center">
      <form class="w-96" onSubmit={handleSubmit}>
        {error && <div className="text-red-500">{error}</div>}
        <Link
          class="mb-2 px-3 py-2 bg-blue-500 text-white block w-full rounded-lg bg-opacity-90 hover:bg-opacity-100 cursor-pointer text-center"
          to="/">Retour à l'accueil</Link>
        <button
          class="px-3 py-2 bg-primary text-white block w-full rounded-lg bg-opacity-90 hover:bg-opacity-100 cursor-pointer"
          disabled={isBusy}
        >
          {isBusy ? "•••" : "Déconnexion"}
        </button>
      </form>
    </main>
  )
}

const SupervisePage = () => {
  const {currentUser, setCurrentUser} = useAuth()
  return currentUser ? <Logout onLogout={() => setCurrentUser(null)} /> : <Login />
}

export default SupervisePage;
